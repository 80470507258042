export interface LocalStorage {
  get<T>(key: string): T | undefined;
  set<T>(key: string, t: T): void;
}

export class SimpleLocalStorage implements LocalStorage {
  get<T>(key: string): T | undefined {
    // throw new Error('Method not implemented.');
    const item = localStorage.getItem(key);
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  }

  set<T>(key: string, t: T): void {
    // throw new Error('Method not implemented.');
    localStorage.setItem(key, JSON.stringify(t));
  }
}
