import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Slide, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { SilosModel } from '../../model/silos-model';
import { UserState, UserStateActions } from './model/user-state';
import { Dispatch, Action } from 'redux';
import { SilosTextfield } from '../components/textfield/SilosTextfield';

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export type SilosLoginProps = {
  user: UserState;
  setNickname: (nickname: string) => void;
};

function SilosLoginHook(props: SilosLoginProps) {
  const { t } = useTranslation('login');

  const [open, setOpen] = useState(false);
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    if (!props.user.nickname) {
      setOpen(true);
    }
  }, [props.user.nickname]);

  function handleLogin() {
    if (!nickname) {
      return;
    }
    props.setNickname(nickname);
    setOpen(false);
    setNickname('');
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition as any}
      disableBackdropClick={true}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent color="primary" id="alert-dialog-slide-description">
        <Typography variant="body1">{t('new-connection')}</Typography>
        <Typography variant="body1">{t('enter-nickname')}</Typography>
        <SilosTextfield label="Nickname" value={nickname} onChange={setNickname} onSubmit={handleLogin} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleLogin} color="primary">
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapState = (state: SilosModel) => ({ user: state.user });
const mapDispatch = (dispatch: Dispatch<Action>) => {
  return {
    setNickname: (nickname: string) => dispatch({ type: UserStateActions.SET_NICKNAME, nickname }),
  };
};
export const SilosLogin = connect(
  mapState,
  mapDispatch,
)(SilosLoginHook);
