import { AnyAction } from 'redux';
import { LocalStorage } from '../../../model/local-storage';

export enum UserStateActions {
  SET_NICKNAME = 'SET_NICKNAME',
  LOGOUT = 'LOGOUT',
}

export type UserState = {
  nickname?: string;
};

export const userStateReducer = (localStorage: LocalStorage) => (state: UserState | undefined, action: AnyAction): UserState => {
  if (!state) {
    return userStateLoad(localStorage);
  }
  let newState = state;
  switch (action.type) {
    case UserStateActions.SET_NICKNAME:
      newState = { nickname: action.nickname };
      break;
    case UserStateActions.LOGOUT:
      newState = { nickname: undefined };
      break;
  }
  localStorage.set('user', newState);
  return newState;
};

export function userStateLoad(localStorage: LocalStorage): UserState {
  return localStorage.get<UserState>('user') || { nickname: undefined };
}
