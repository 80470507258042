import React, { useState } from 'react';
import { Container, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { SilosTitleBar } from './silos-title-bar';
import { SilosMenuBar } from './silos-menu-bar';
import { SiloCrt, SiloCrtEffect } from './silos-crt';
import { SilosMain } from './silos-main';
import { MainWindow } from '../model/main-window';

const screenStyle = makeStyles((theme: Theme) => {
  return createStyles({
    screen: {
      maxHeight: '100vh',
      minHeight: '100vh',
      height: '100vh',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    allHeight: {
      flexGrow: 1,
      display: 'flex',
    },
    allWidth: {
      width: '100vw',
      padding: 0,
    },
  });
});

export function SwitchOsScreen() {
  const classes = screenStyle();
  const [mainWindow, setMainWindow] = useState(MainWindow.EMAILS);

  return (
    <Container maxWidth={false} className={classes.allWidth}>
      <SiloCrt />
      <div className={classes.screen}>
        <SilosTitleBar />
        <div className={classes.allHeight}>
          <SilosMenuBar select={i => setMainWindow(i)} value={mainWindow} />
          <SilosMain value={mainWindow} />
        </div>
      </div>
      <SiloCrtEffect />
    </Container>
  );
}
