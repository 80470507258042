import React from 'react';
import { Typography } from '@material-ui/core';
import { MainWindow } from '../model/main-window';

type PanelProps = {
  children?: React.ReactNode;
  value: MainWindow;
  index: MainWindow;
};

function TabPanel(props: PanelProps) {
  const { children, value, index } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`}>
      {children}
    </Typography>
  );
}

export type SilosMainProps = {
  value: MainWindow;
};

export function SilosMain(props: SilosMainProps) {
  return (
    <div>
      <TabPanel value={props.value} index={MainWindow.EMAILS}>
        Emails
      </TabPanel>
      <TabPanel value={props.value} index={MainWindow.EDITOR}>
        Editor
      </TabPanel>
      <TabPanel value={props.value} index={MainWindow.CAMERA}>
        Camera
      </TabPanel>
      <TabPanel value={props.value} index={MainWindow.STATS}>
        Statistics
      </TabPanel>
      <TabPanel value={props.value} index={MainWindow.FORUM}>
        News &amp; Network
      </TabPanel>
    </div>
  );
}
