import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { colorBackground } from './theme';

const crtStyle = makeStyles((_theme: Theme) => {
  return createStyles({
    crtBack: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: colorBackground,
      width: '100vw',
      height: '100vh',
      zIndex: -1,
    },
    crtEffect: {
      '&::before': {
        content: '" "',
        right: 0,
        bottom: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        background: `linear-gradient(rgb(255,255,255,0.2) 50%, rgb(0,0,0, 0.2) 50%)`,
        backgroundSize: '100% 3px',
        zIndex: 2000,
        pointerEvents: 'none',
      },
    },
  });
});

export function SiloCrt() {
  const classes = crtStyle();
  return <div className={classes.crtBack}></div>;
}
export function SiloCrtEffect() {
  const classes = crtStyle();
  return <div className={classes.crtEffect}></div>;
}
