import React, { Suspense } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import { SwitchOsScreen } from './silos/silos-screen';
import { CssBaseline } from '@material-ui/core';
import { switchTheme } from './silos/theme';
import { SilosReducers } from './model/silos-model';

const store = createStore(SilosReducers);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={switchTheme}>
        <CssBaseline />
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/" component={SwitchOsScreen} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
