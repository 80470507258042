import React from 'react';
import { makeStyles, Theme, createStyles, Badge, Icon, ButtonBase } from '@material-ui/core';
import { neonWhite, neonBlueAlpha, neonLitOff } from './theme';
import { iconWidth, separatorLength, boxShadow } from './silos-title-bar';
import { MainWindow } from '../model/main-window';

const bottomDiag = separatorLength / 2;
const bottomDiagSide = Math.sqrt((bottomDiag * bottomDiag) / 2);
const bottomSpace = 10;

const styles = makeStyles((_theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    menuBar: {
      display: 'flex',
      width: `${iconWidth + separatorLength}px`,
      // height: '95%',
      flexGrow: 1,
    },
    menu: {
      flexBasis: `${iconWidth + (separatorLength - separatorLength * Math.cos(Math.PI / 4))}px`,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'space-evenly',
    },
    neonMenu: {
      borderRight: `1px solid ${neonWhite}`,
      flexBasis: '0px',
      boxShadow,
    },
    emptyMenu: {
      flexGrow: 1,
    },
    iconOff: {
      color: `${neonLitOff}`,
      '&:hover': {
        backgroundColor: `${neonBlueAlpha(0.3)}`,
        boxShadow: `0 0 6px 1px ${neonBlueAlpha(0.8)}`,
      },
    },
    iconOn: {
      color: `inherit`,
      textShadow: 'inherit',
      '&:hover': {
        backgroundColor: `${neonBlueAlpha(0.3)}`,
        boxShadow: `0 0 6px 1px ${neonBlueAlpha(0.8)}`,
      },
    },
    menuFooter: { display: 'flex', marginBottom: `${bottomSpace}px`, marginTop: `${bottomDiagSide - 1}px` },
    menuFooterBottom: {
      borderBottom: `1px solid ${neonWhite}`,
      flexBasis: `${1 + iconWidth + (separatorLength - separatorLength * Math.cos(Math.PI / 4)) - bottomDiagSide}px`,
      height: '0px',
      boxShadow,
    },
    menuFooterDiagonal: {
      flexBasis: `${bottomDiag}px`,
      height: '1px',
      borderBottom: `1px solid ${neonWhite}`,
      transformOrigin: `0 1px`,
      transform: `rotate(-45deg)`,
      boxShadow,
    },
  });
});

export type SilosMenuActionsStrings = keyof typeof MainWindow;

export type SilosMenuBarProps = {
  value: MainWindow;
  select: (index: MainWindow) => void;
};

function toIcon(action: MainWindow) {
  switch (action) {
    case MainWindow.EMAILS:
      return <Icon>email_outlined</Icon>;
    case MainWindow.EDITOR:
      return <Icon>description_outlined</Icon>;
    case MainWindow.CAMERA:
      return <Icon>videocam_outlined</Icon>;
    case MainWindow.STATS:
      return <Icon>insert_chart_outlined</Icon>;
    case MainWindow.FORUM:
      return <Icon>forum_outlined</Icon>;
  }
}

export function SilosMenuBar(props: SilosMenuBarProps) {
  const classes = styles();
  return (
    <div className={classes.root}>
      <div className={classes.menuBar}>
        <div className={classes.menu}>
          {Object.keys(MainWindow).map((actionString, index) => {
            const action = MainWindow[actionString as SilosMenuActionsStrings];
            return (
              <ButtonBase key={index} onClick={() => props.select(action)} className={props.value === action ? classes.iconOn : classes.iconOff}>
                <Badge badgeContent={1}>{toIcon(action)}</Badge>
              </ButtonBase>
            );
          })}
        </div>
        <div className={classes.neonMenu}></div>
        <div className={classes.emptyMenu}></div>
      </div>
      <div className={classes.menuFooter}>
        <div className={classes.menuFooterBottom}></div>
        <div className={classes.menuFooterDiagonal}></div>
      </div>
    </div>
  );
}
