import React from 'react';
import { Typography, IconButton, Icon, makeStyles, Theme, createStyles } from '@material-ui/core';
import { neonWhite, neonBlue } from './theme';
import { SilosLogin } from './login/login';
import { SilosModel } from '../model/silos-model';
import { connect } from 'react-redux';
import { UserState, UserStateActions } from './login/model/user-state';

export const iconWidth = 48;
export const separatorLength = 40;
export const boxShadow = `0px 1px 3px 1px ${neonBlue}, inset 0px -3px 3px 1px ${neonBlue}`;

const screenStyle = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      margin: 0,
      display: 'flex',
      alignItems: `center`,
    },
    margin: {
      flexBasis: `${iconWidth + separatorLength}px`,
    },
    marginIcons: {
      flexBasis: `${iconWidth}px`,
    },
    titleContent: {
      flexGrow: 1,
    },
    icon: {
      color: 'inherit',
      textShadow: 'inherit',
    },
    neonLine: {
      display: 'flex',
      height: `${separatorLength * Math.cos(Math.PI / 4)}px`,
      alignItems: `flex-start`,
    },
    neonDiagonalLine: {
      flexBasis: `${separatorLength}px`,
      borderTop: `1px solid ${neonWhite}`,
      transformOrigin: `${separatorLength}px 0`,
      transform: `rotate(-45deg)`,
      boxShadow,
    },
    neonLineBorder: {
      borderBottom: `1px solid ${neonWhite}`,
      boxShadow,
      flexGrow: 1,
      height: `1px`,
    },
  });
});

type SilosTitleBarHookProps = {
  user: UserState;
  logout: () => void;
};

function SilosTitleBarHook(props: SilosTitleBarHookProps) {
  const classes = screenStyle();

  return (
    <>
      <div className={classes.title}>
        <div className={classes.margin}></div>
        <div className={classes.titleContent}>
          <Typography variant="h5">
            SilOS <Typography variant="caption">v1.0.0</Typography>
          </Typography>
        </div>
        <div>
          <Typography variant="h6">{props.user.nickname}</Typography>
        </div>
        <div>
          <IconButton className={classes.icon} onClick={props.logout}>
            <Icon>power_settings_new_sharp</Icon>
          </IconButton>
        </div>
      </div>
      <div className={classes.neonLine}>
        <div className={classes.marginIcons}></div>
        <div className={classes.neonDiagonalLine}></div>
        <div className={classes.neonLineBorder}></div>
      </div>
      <SilosLogin />
    </>
  );
}

export const SilosTitleBar = connect(
  (state: SilosModel) => ({
    user: state.user,
  }),
  dispatch => ({
    logout: () => dispatch({ type: UserStateActions.LOGOUT }),
  }),
)(SilosTitleBarHook);
