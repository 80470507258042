import { combineReducers } from 'redux';
import { userStateReducer, UserState } from '../silos/login/model/user-state';
import { SimpleLocalStorage } from './local-storage';

export type SilosModel = {
  user: UserState;
};

export const SilosReducers = combineReducers({
  user: userStateReducer(new SimpleLocalStorage()),
});
