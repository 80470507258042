import React from 'react';
import { TextField } from '@material-ui/core';

export type SilosTextfieldProps = {
  label: string;
  value: string;
  onChange: (s: string) => void;
  onSubmit: () => void;
};

export function SilosTextfield(props: SilosTextfieldProps) {
  return (
    <TextField
      label={props.label}
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      onKeyPress={e => {
        if (e.charCode === 13) {
          props.onSubmit();
        }
      }}
    />
  );
}
